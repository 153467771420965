.hero {

  position: relative;
  background-size: cover;
  // background-color: $primary;
  
  .darken {
    height: 700px;
    background-color: rgba(#014991, 0.75);
  }

  .content {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;

    .container {

      .bajada {

        h1, h2, h3, h4, h5, h6, p {
          color: white;
        }
  
        #logo_empresa {
          width:325px;
        }

        .handwriting {
          // font-family: 'Allura', cursive;
          // font-size: 4rem; //allura
          font-family: 'Pacifico', cursive;
         
        }

        h1 {
          // line-height: 2.75rem;
          span {
            text-transform: uppercase;
            font-weight: 400;
          }
        }

        
        h5 {
          &.lead {
            font-size: 1.50rem;
          }
        }
  
      }

      form {

        label {
          margin-bottom: 0.25rem;
        }
        
        .btn {
          margin-top: 1rem;
          // text-transform: uppercase;
          color: white;
          &:hover {
            // background-color: $cta;
          }
        }
      }
            
    

    }
  }
}


@media (max-width: 767px) {
  .hero {
    background-position: center right;
    background-image: url('../../statics/imagen-sierras-cordoba.jpg');
    .darken {
      min-height:1450px;
      // background-color: rgba($primary, 0.75);
    }

    .content {
      // height: 100%;
      .container {
        text-align: center;
        .bajada {

          h2 {
            font-size: 2.75rem;
          }

          h1 {
            font-size: 2.6rem;
            span {
              // line-height: 1.75rem;
              font-size: 1.5rem;
            }
          }

          h3 {
            font-size: 1.90rem;
          }

          h4 {
            font-size: 1.3rem;
          }
          
          #logo_empresa {
            width: 300px;
          }
        }

        .form {
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
        }

      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .hero {
    background-position: center bottom;
    background-image: url('../../statics/imagen-sierras-cordoba.jpg');
    .darken {
      // background-color: rgba(#000, 0.5);
    }

    .content {

      .container {

        .bajada {
          margin-top: 40px;

          h1 {
            font-size: 3rem;
            line-height: 2.75rem;
            span {
              font-size: 1.25rem;
            }
          }

          h2 {
            font-size: 2.75rem;
          }

          h3 {
            font-size: 1.75rem;
          }
        }
      }
    }

  }
}

@media (min-width: 992px) and (max-width: 1199px) {

  .hero {
    background-position: center bottom;
    background-image: url('../../statics/imagen-sierras-cordoba.jpg');
    .darken {
      // background-color: rgba(#000, 0.5);
    }

    .content {

      .container {

        .bajada {
          margin-top: 40px;

          h1 {
            font-size: 2.5rem;
            line-height: 2.75rem;
            span {
              font-size: 1.5rem;
            }
          }

          h2 {
            font-size: 3rem;
          }

          h3 {
            font-size: 2.4rem;
          }
        }
      }
    }

  }

}

@media (min-width: 1200px) {

  .hero {
    background-position: center bottom;
    background-image: url('../../statics/imagen-sierras-cordoba.jpg');

    .content {

      .container {

        .bajada {
          margin-top: 30px;

          h1 {
            line-height: 2.75rem;
            span {
              font-size: 1.75rem;
              text-transform: uppercase;
              font-weight: 400;
            }
          }
  
          h3 {
            font-size: 2.45rem;
          }

        }
      }
    }

  }

}