@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');

// Fuente manuscrita para el hero
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Sarina&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Meddon&display=swap');

$font-family-base: 'Work Sans', sans-serif;
$font-family-serif: 'Merriweather', serif !default;


$primary: #0071e3;
$secondary: #88be4a;
$general-text: #333;
$danger: #fb2121;
// $danger: #eb5757;
$success: #27a327;
$info: #00c48c;

$gray: #f4f4f4;
// $gray: #e2e2e2;
// $cta: #eb5757;