footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #111;
  text-align: center;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  .wip{
    height: 16px;
  }
}