.benefits {

  // background-color: #f4f4f4;
  padding: 50px 0;
  text-align: center;


  p {
    font-weight: 300;
  }



  // margin-top: 2rem;
  // margin-bottom: 2rem;

  .container {

    padding-top: 30px;
    padding-bottom: 30px;

    background-color: white;

    h2 {
      font-size: 2.4rem;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0;
    }


    .benefits-item {

      margin-bottom: 1.5rem;

      .benefits_item_icon {
        height: 110px;
        width: 110px;
        margin: auto;
        border-radius: 50%;
        background-color: white;
        border: 4px solid $success;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .rrss_icon{
          color: $general-text;
        }
       
      }

      .benefits_item_text {
        margin-top: 1rem;
        font-size: 1.35rem;
        line-height: 2rem;
        font-weight: 500;
      }
    }
  }
}

@media (max-width: 767px) {
  .benefits {
    padding: 0;
    .container {
      .benefits-item {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;

        .benefits-item-icon {
          height: 175px;
          width: 175px;
        }
        .benefits-item-title {
          font-size: 1.6rem;
          line-height: 2.25rem;

        }
      }
    }
  }
}
