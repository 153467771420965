    
$hero_heigth : 250px;

.availables {

    background-position: center bottom;
    background-image: url('../../statics/hero-veneto-village.jpg');

    div {

        position: relative;
        width: 100%;
        object-fit: cover;

        .overlay {
            height: $hero_heigth;
            // position: absolute;
            // background-color: white;
            z-index: 1;
            top: 0;
            width: 100%;
            background: -webkit-linear-gradient(top,transparent 70%,rgba(0,0,0,1));
            background: linear-gradient(180deg,transparent 70%,rgba(0,0,0,1));
        }

       
        .content {

            position: absolute;
            z-index: 2;
            top: 0;
            width: 100%;

            .container {
                height: $hero_heigth;
                display: -moz-box;
                display: flex;
                -moz-box-pack: justify;
                justify-content: space-between;

                .info {
                    color: #fff;
                    align-self: flex-end;
                    margin-bottom: 4px;

                    h1 {

                        &.big-title {

                            text-shadow: 0 0 9px rgba(0,0,0,.7);

                            span {
                                text-transform: uppercase;
                                font-weight: 700;
                            }
                            small {
                                font-size: 2rem;
                                font-weight: 300;
                            }
                        }

                    }
                }


            }

        }

    }


    @media (max-width: 767px) {

        .content {

            

            .container {
                height: $hero_heigth;
                
                .info {
                    

                    h1 {

                        &.big-title {

                            font-size: 3rem;
                            span {
                                
                            }
                            small {
                                font-size: 1.65rem !important;
                                // font-weight: 300;
                            }
                        }

                    }
                }


            }

        }


      
    }

    

    @media (min-width: 1200px) {

    }

    @media (min-width: 768px) and (max-width: 1199px) {

       
    }


}
