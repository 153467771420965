    
$hero_heigth : 350px;

.product {

  padding-top: 50px;
  padding-bottom: 50px;

  .container {

    background-color: white;

    padding-bottom: 20px;

    .summary {

        h2 {
          font-size: 3rem;
          font-weight: 700;
          margin-bottom: 0;

          small, .small {
            font-size: 80%;
            font-weight: 400;
          }


        }

        h3 {
          text-transform: uppercase;
          font-weight: 400;
          margin-bottom: 0;
        }
    }

    .slides {
      margin-bottom: 40px;
      // .carousel-control-prev,
      // .carousel-control-next {
      //   background-color: white;
      //   height: 43px;
      //   width: 54px;
      //   margin: auto 0;
      //   .carousel-control-prev-icon,
      //   .carousel-control-next-icon {
      //     background-size: 10px;
      //     opacity: 1;
      //   }
      // }
      .carousel-inner {
        .carousel-item {
          img {
            width: 100%;
            // height: 550px;
            object-fit: cover;
          }
        }
      }
    }

    $height-desktop: 450px;
    $height-mobile: 400px;
    .location {
      background-color: #000;
      color: white;
      .content {
        position: relative;
        height: $height-desktop;
        .content-image {
          height: $height-desktop;
          width: 100%;
          object-fit: cover;
        }
        .content-overlay {
          height: $height-desktop;
          width: 100%;
          background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
          position: absolute;
          z-index: 1;
          top: 0;
        }
        .content-info {
          //background-color: orange;
          text-align: center;
          width: 100%;
          position: absolute;
          z-index: 2;
          top: 0;
          padding: 50px 20%;
          h2 {
            margin-bottom: 30px;
            font-weight: 400;
          }
          h3 {
            margin-bottom: 30px;
            font-size: 1.25rem;
            font-weight: 700;
          }
          p {
            font-size: 1.05rem;
          }
        }
      }

      .mapa {
        position: relative;
        height: $height-desktop;
        iframe {
          opacity: 0.96;
          position: absolute;
          height: $height-desktop;
          width: 100%;
          box-sizing: 0;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  



  @media (max-width: 767px) {
    .summary {
      padding: 1rem;
    }

    .slides {
     
      .carousel-inner {
        .carousel-item {
          img {
            height: 350px;
          }
        }
      }
    }
  }

  
  @media (min-width: 768px) and (max-width: 1199px) {

    .summary {
      padding: 2.5rem;
    }

    .slides {
     
      .carousel-inner {
        .carousel-item {
          img {
            height: 450px;
          }
        }
      }
    }
  }
  
  @media (min-width: 1200px) {
    .summary {
      padding: 2.75rem;
    }

    .slides {
     
      .carousel-inner {
        .carousel-item {
          img {
            height: 550px;
          }
        }
      }
    }

  }



}
